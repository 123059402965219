[
    ["16/11/2020 - 18:29:52; ME: Nesrin cidden kusura bakma, yemeği de yiyip ayrılmak isterdim de fizyolojim el vermedi, seni de yürüttüm bir de hem de tek başına teşekkürler bu gün için, tekrardan afiyet olsun",
    "16/11/2020 - 18:37:50; Nesrinim: Ben tesekkur ederim",
    "16/11/2020 - 18:37:56; Nesrinim: Sorun değill",
    "16/11/2020 - 18:37:59; Nesrinim: Sana da afiyet olsun"],
    ["17/11/2020 - 18:13:56; ME: Konuştuklarımızdan bunlar var istediğin zaman verebilirim",
    "17/11/2020 - 18:14:40; ME: Hatta istersen heyecanlı olsun diye tek tek verme servisim bile var okudukça",
    "17/11/2020 - 18:37:10; Nesrinim: En sevdiğim smdjhdhc",
    "17/11/2020 - 18:38:18; Nesrinim: Seçeyim mi birini şimdi yarın getirebilir misin",
    "17/11/2020 - 18:39:53; ME: Sjsjjdkd çok ağır olmaz gibi",
    "17/11/2020 - 18:40:11; ME: Seç tabi ki",
    "17/11/2020 - 18:40:22; ME: Bitti mi dünkülerden biri cidden o.o",
    "17/11/2020 - 18:45:30; Nesrinim: [ Image: Media/WhatsApp Images/IMG-20201117-WA0010.jpg ]",
    "17/11/2020 - 18:45:35; Nesrinim: Bunu bitirdim",
    "17/11/2020 - 18:46:24; ME: Tebrikler valla ben daha yeni kendime geldim dış dünya beni yoruyor",
    "17/11/2020 - 18:47:12; ME: Bu arada şu an okumam gereken bir sürü kitap birikti ama bir ara ben de bunu senden alayım bende yok bu",
    "17/11/2020 - 18:48:31; Nesrinim: [ Quote from 17/11/2020 - 18:46:24: 1BF6E62A7A1EC79C04C68A241662C06F ]; O kadar tahmin ediyorum ki",
    "17/11/2020 - 18:48:38; Nesrinim: 50 sayfaydı be",
    "17/11/2020 - 18:48:49; Nesrinim: Güzelmiş ama çok sevdim kitabı",
    "17/11/2020 - 18:51:03; ME: En güzeli bir oturuşta okumak",
    "17/11/2020 - 18:51:15; ME: Diziler gibi kitapları da yarım bırakıyorum bdkdkddkfk",
    "17/11/2020 - 18:51:42; ME: Ama ufak ufak yeniden başlıyorum kitap okumaya bayadır çook boşladım",
    "17/11/2020 - 18:52:23; Nesrinim: Ben de çok ciddi okuyucu gibi gorundum galiba",
    "17/11/2020 - 18:52:28; Nesrinim: Pek sayılmaz aslında",
    "17/11/2020 - 18:52:35; ME: Fkdkdkkd ben korktum valla",
    "17/11/2020 - 18:52:36; Nesrinim: Vakit buldukça diyelim sjdhhs",
    "17/11/2020 - 18:54:02; ME: Ben ciddiyetim hakkında konyşmiyim djdjdks",
    "17/11/2020 - 18:54:55; Nesrinim: Sen başka şeylerle uğraşıyorsun",
    "17/11/2020 - 18:55:07; Nesrinim: Bak bize bilgisayarı isim olarak biliyoruz sadece ksjdhehd",
    "17/11/2020 - 18:56:59; ME: Avutuldum şu an sksnzjz",
    "17/11/2020 - 18:57:39; ME: Ben ciddi yürüyücüyüm ya 4 saat yürüyorum şikayet etmeden ama 40 dakika kitap okuyunca uykum geliyor",
    "17/11/2020 - 18:58:18; ME: Geçen hatta şu storytele baktım sesli kitap olayına herhalde onu denicem odaklanabilirsem baya verimli olur gibi geldi bana",
    "17/11/2020 - 19:02:03; Nesrinim: Ben hiç bilmiyorum sesli kitap işini",
    "17/11/2020 - 19:02:07; Nesrinim: Dedim ya ben baya geleneksel",
    "17/11/2020 - 19:02:15; Nesrinim: Babaanne yontemleriyle okuyorum sjjdjsjd"],
    ["19/11/2020 - 20:57:42; Nesrinim: Bu arada kitaba tarih bile atmamış olman...",
    "19/11/2020 - 20:57:59; ME: Ohoo",
    "19/11/2020 - 20:58:25; ME: [ Quote from 19/11/2020 - 20:57:30: 54BD076F6D7E997C0339329ACF213973 ]; Tamam daha komunizmden vazgeçiricem seni",
    "19/11/2020 - 20:59:59; Nesrinim: Anlaştııık",
    "19/11/2020 - 21:00:27; ME: Kfjkd gidiyorum ama son bir şey dicem",
    "19/11/2020 - 21:00:37; ME: [ Quote from 19/11/2020 - 20:59:59: 5C3C5DC00169178F88EC8FA920ABBD50 ]; Bu kadar kolay olmasını beklemiyordum kfkdkdkdkfkfkf",
    "19/11/2020 - 21:00:49; ME: Hadi iyi çalışmalar sana görüşürüz",
    "19/11/2020 - 21:01:05; Nesrinim: Ndjcjjdjc",
    "19/11/2020 - 21:01:22; Nesrinim: Fikirlerimizin sahibiyiz kölesi değiliz temalı konuşma yapardım da",
    "19/11/2020 - 21:01:36; Nesrinim: Çalışıyorum boş bir zamanda yapayım",
    "19/11/2020 - 21:01:37; ME: (ders çalışmam lazım)",
    "19/11/2020 - 21:01:40; ME: Ejdkdjdddlldd",
    "19/11/2020 - 21:01:41; Nesrinim: Jdjduhshdhdhudud",
    "19/11/2020 - 21:01:41; ME: Tuttu",
    "19/11/2020 - 21:01:57; Nesrinim: Görüşürüzz",
    "21/11/2020 - 18:47:22; ME: Size iyi akşamlar, görüşmek üzere",
    "21/11/2020 - 18:47:35; ME: ",
    "21/11/2020 - 18:48:01; Nesrinim: Teşekkürler efenim",
    "21/11/2020 - 18:48:05; Nesrinim: İyi akşamlar"],
    ["28/11/2020 - 14:22:55; ME: Nasıl gidiyor başladın mı çalışmaya ._.",
    "28/11/2020 - 14:32:51; Nesrinim: Ndjchdhc",
    "28/11/2020 - 14:33:00; Nesrinim: Hoş mu bu yaptığın...",
    "28/11/2020 - 14:33:06; ME: Dkkdkdkdkd",
    "28/11/2020 - 14:33:28; ME: Şaka şaka, ben devrine başladın mı çok iddialı olur diye düşündüm",
    "28/11/2020 - 14:33:56; ME: Bu arada bence hala komik jffjfkfjfkof",
    "28/11/2020 - 14:34:14; Nesrinim: Skjchsuchdh",
    "28/11/2020 - 14:34:17; Nesrinim: Bence de komik",
    "28/11/2020 - 14:34:24; Nesrinim: Bekliyordum böyle mesajlar",
    "28/11/2020 - 14:34:36; Nesrinim: Ben ilk tarik der diye düşündüm sadece ldjdjdjch",
    "28/11/2020 - 14:34:45; ME: Siber zorbalık",
    "28/11/2020 - 14:35:13; ME: Favori aktivitelerimden",
    "28/11/2020 - 14:35:14; ME: ",
    "28/11/2020 - 14:36:23; Nesrinim: Sen de mi ..",
    "28/11/2020 - 14:37:38; ME: Yok ya vazgeçtim herhalde ben boş yapmayı seviyorum",
    "28/11/2020 - 14:38:14; Nesrinim: Bu daha iyi",
    "28/11/2020 - 14:38:18; Nesrinim: Ben okeyim skjxjehch",
    "28/11/2020 - 14:38:37; Nesrinim: Herkes kuruyemis almak icin kuyrukta",
    "28/11/2020 - 14:38:40; Nesrinim: Normal mi bu .s.s",
    "28/11/2020 - 14:38:46; ME: Oo yine dışarıdayız",
    "28/11/2020 - 14:39:16; ME: 10 saat çerezsiz napar insanlık dkskdk",
    "28/11/2020 - 14:39:46; Nesrinim: Ben oje falan almak için çıktım",
    "28/11/2020 - 14:39:50; Nesrinim: Temel ihtiyaç",
    "28/11/2020 - 14:39:56; Nesrinim: Ama kuruyemiş bilemedim",
    "28/11/2020 - 14:39:58; Nesrinim: Wjhdhshxh",
    "28/11/2020 - 14:41:24; ME: Vay be ben de normal insan saatlerinde yürüyüşe çıkmayı planlıyordum katılır mısın dicektim yine planlar uymadı",
    "28/11/2020 - 14:41:55; ME: [ Quote from 28/11/2020 - 14:39:50: A824AFA913D379B51EB6333B5FF7BCED ]; Ben de aslında gizli gizli oje sürüyorum dkdkf",
    "28/11/2020 - 14:42:10; ME: Herkes yapıyor ki temel ihtiyaç zaten",
    "28/11/2020 - 14:42:16; Nesrinim: Jshchsjhchducuuduc",
    "28/11/2020 - 14:42:34; Nesrinim: ",
    "28/11/2020 - 14:42:55; ME: Cidden siyah ojem vardı evde fkfkdk",
    "28/11/2020 - 14:43:13; ME: Ama amacı çok daha güzeldi dkkdldkdkf",
    "28/11/2020 - 14:43:14; Nesrinim: Ay neden",
    "28/11/2020 - 14:43:18; Nesrinim: [ Quote from 28/11/2020 - 14:43:13: E047EE77CF84AF0E45D5947E3470EE50 ]; Oh",
    "28/11/2020 - 14:43:23; Nesrinim: Neden kdjxidjf",
    "28/11/2020 - 14:43:41; ME: Şimdi oje türbanlı bir hanımefendi olarak tasarlanmış, böyle masada çevirince break dans yapıyor gibi oluyordu",
    "28/11/2020 - 14:43:43; ME: Fnkfkfkflflfkflkf",
    "28/11/2020 - 14:44:04; ME: Tamamen bu amaçla lisede bir arkadaşıma bu ojeden bana da alır mısın diye rica etmiştim",
    "28/11/2020 - 14:44:19; Nesrinim: Bu ne çeşit bir manyaklik",
    "28/11/2020 - 14:44:20; ME: Sonra kayboldu taşınırken",
    "28/11/2020 - 14:44:23; Nesrinim: Yuce rabbim",
    "28/11/2020 - 14:44:33; ME: Ama bak ön yargılı yaklaşma",
    "28/11/2020 - 14:44:49; ME: Böyle türbanlı teyze sırtının üstünde dönüyor gibi görünüyor",
    "28/11/2020 - 14:44:57; ME: Anlattıkça çirkin geliyor kdkfkdkkr",
    "28/11/2020 - 14:45:01; ME: Ama komikti inan",
    "28/11/2020 - 14:45:03; ME: ",
    "28/11/2020 - 14:45:04; Nesrinim: Anlattıkça",
    "28/11/2020 - 14:45:10; Nesrinim: Sana on yargim artıyor",
    "28/11/2020 - 14:45:16; Nesrinim: Boşver ksjcjdjc",
    "28/11/2020 - 14:45:36; ME: Bana değil tasarlayanlara yönlendir bu ön yargıyı",
    "28/11/2020 - 14:45:43; ME: Benimki bir tepkiydi sadece",
    "28/11/2020 - 14:45:59; Nesrinim: Arz talep",
    "28/11/2020 - 14:46:05; Nesrinim: Alındığı için üretiliyor",
    "28/11/2020 - 14:46:14; Nesrinim: Yine sana doğru ön yargım",
    "28/11/2020 - 14:46:15; Nesrinim: Sad",
    "28/11/2020 - 14:46:49; ME: Aman tamam ya zaten kimse bu komik olayla ben kadar eğlenmedi",
    "28/11/2020 - 14:47:06; ME: Gerçi taklit edince gülüyordu insanlar ama böyle iyice havada kaldı kdkfkfjf",
    "28/11/2020 - 14:47:38; Nesrinim: Ofansif fazlaca",
    "28/11/2020 - 14:47:39; Nesrinim: Sevmedim",
    "28/11/2020 - 14:47:40; Nesrinim: Ksjfjejc",
    "28/11/2020 - 14:48:17; ME: Neyse daha fazla uzatmiyim bu konuyu savunması çok zor",
    "28/11/2020 - 14:48:28; ME: Yani savunamayacağımdan değil kesinlikle",
    "28/11/2020 - 14:48:32; Nesrinim: O değil de",
    "28/11/2020 - 14:48:32; ME: Yanlış anlaşılmasın jejdks",
    "28/11/2020 - 14:48:36; Nesrinim: [ Quote from 28/11/2020 - 14:48:28: EE0E50D2897EE0C10F9C60F9FA0E4634 ]; 🙄",
    "28/11/2020 - 14:48:45; Nesrinim: Ben 4 saat yürüyemem ksjchdhf",
    "28/11/2020 - 14:49:18; ME: Yok ya bir Atatürk parkı olabilirdi, zaten senin beni limitlemen lazım dışarısı çok pahalı bir saatten sonra",
    "28/11/2020 - 14:50:07; ME: Hatta seni kandırmak için bahanem de hazırdı, migrostan geçeriz poşetlerine yola kadar yardım ederim sayın migrossever diyicektim",
    "28/11/2020 - 14:50:15; ME: ",
    "28/11/2020 - 14:50:25; Nesrinim: Msjcjdjcjjdhc",
    "28/11/2020 - 14:50:28; Nesrinim: Kanardım",
    "28/11/2020 - 14:50:36; Nesrinim: Iyi tanımışsın ksucuduc",
    "28/11/2020 - 14:51:01; ME: Yani benim için hala geçerli bir teklif de işte senin hareketlerini planlayamıyorum",
    "28/11/2020 - 14:51:13; ME: İnsan olmak hayli zor",
    "28/11/2020 - 14:52:33; Nesrinim: Bugün olmaz dışarıdayım da",
    "28/11/2020 - 14:52:40; Nesrinim: Sen hergün yürüyorsun galiba",
    "28/11/2020 - 14:52:44; Nesrinim: Bir dahakine eşlik edeyim"],
    ["28/11/2020 - 15:59:48; ME: Aklıma şey geldi bana program falan ne yaparsan benim için bilgisayar oyunu demiştin fjjfkfkfmfkff",
    "28/11/2020 - 16:01:01; ME: Şimdi anlaşıldı 😅",
    "28/11/2020 - 16:02:20; Nesrinim: Öyle mi demistim",
    "28/11/2020 - 16:02:29; Nesrinim: Ayıp etmişim sanki"],
    ["29/11/2020 - 15:32:08; ME: Heyo ben yürüyüşe çıkıcam da müsait misin bilemedim söyledi çağırmadı da olmak istemedim bir dürteyim dedim kdkdkdkd",
    "29/11/2020 - 15:59:15; Nesrinim: Ne kadar isteksiz bir teklif",
    "29/11/2020 - 15:59:20; Nesrinim: Utanıp evet diyemedim",
    "29/11/2020 - 15:59:58; ME: Flldkdkd yaw müsait değilsen boşa çıkıcak diye",
    "29/11/2020 - 16:00:38; ME: Darlamayı sevmiyorum insanları",
    "29/11/2020 - 16:00:52; Nesrinim: Olmuş biraz yazalı",
    "29/11/2020 - 16:00:56; Nesrinim: Çıktın mi sen",
    "29/11/2020 - 16:01:03; ME: Evet",
    "29/11/2020 - 16:01:14; ME: Neredeyim bilmiyorum ama spesifik olarak fkodkdkdkdkfkfkdd",
    "29/11/2020 - 16:01:54; ME: Katılmak istersen rotamı ona göre ayarlayabilirim",
    "29/11/2020 - 16:02:30; Nesrinim: Nerdesin diyemiyorum",
    "29/11/2020 - 16:02:36; Nesrinim: Tüm sorularımı kapattin be",
    "29/11/2020 - 16:02:50; ME: Garın o tarafta",
    "29/11/2020 - 16:03:18; ME: Dur mape bakiyim tam",
    "29/11/2020 - 16:03:57; ME: Aynen garın oradaymışım dkdkkdd",
    "29/11/2020 - 16:04:14; Nesrinim: Skjxjdjjc",
    "29/11/2020 - 16:04:28; Nesrinim: Ee napıcaz şimdi",
    "29/11/2020 - 16:04:34; Nesrinim: Kafam karıştı",
    "29/11/2020 - 16:05:02; Nesrinim: Salayım seni istersen bugün tek mi yürümek istiyordun😂",
    "29/11/2020 - 16:05:20; ME: Yok ya mesaj iletilmeyince",
    "29/11/2020 - 16:05:46; ME: Şöyle diyim sen çık desem hemen çıkabilir misin",
    "29/11/2020 - 16:05:54; Nesrinim: Uyuyordum",
    "29/11/2020 - 16:05:56; Nesrinim: Ksjcjdjjcjd",
    "29/11/2020 - 16:05:59; ME: Kdkdkdlsld",
    "29/11/2020 - 16:06:12; Nesrinim: Bir 10 dakikaya çıkarım",
    "29/11/2020 - 16:06:29; ME: Tamam ben de o zamana kadar oralara yaklaşırım",
    "29/11/2020 - 16:06:43; ME: Anayola çıkarsam halledicem sen hazırlan istersen",
    "29/11/2020 - 16:06:55; Nesrinim: Atatürk parkına doğru yürürüz",
    "29/11/2020 - 16:07:02; Nesrinim: Ona göre şey etsen istersen",
    "29/11/2020 - 16:07:12; ME: Tamam sen çıkarken haber ver haberleşiriz",
    "29/11/2020 - 16:07:18; Nesrinim: Tamamm",
    "29/11/2020 - 16:19:20; Nesrinim: Çıkıyorum",
    "29/11/2020 - 16:19:37; ME: Çilek mobilyanın oradayım",
    "29/11/2020 - 16:19:52; ME: Sen karşıya nereden geçiyorsun geçen geçtiğimiz köprü mü",
    "29/11/2020 - 16:20:11; Nesrinim: Evet",
    "29/11/2020 - 16:20:17; Nesrinim: Yani cok karsiya geçmiyorum",
    "29/11/2020 - 16:20:22; ME: Dkdkskdkdk",
    "29/11/2020 - 16:20:24; Nesrinim: Gecsem ordan gecerim herhalde",
    "29/11/2020 - 16:20:26; Nesrinim: Ksjdhshhxhd",
    "29/11/2020 - 16:20:29; ME: Orda durakta bekliyorum",
    "29/11/2020 - 16:20:36; Nesrinim: Tamam",
    "29/11/2020 - 16:20:36; ME: 2dk soluklanayım",
    "29/11/2020 - 19:34:48; ME: Yarın için bir planın var mı sözüm meclisten dışarı sınav çalışmaları başlamadan pencereyi yakaliyim diyorum",
    "29/11/2020 - 19:36:06; ME: Bu arada benim hatırladığım ekmekmiş pizza hamuru 30 dakika civarında hamurlanıyormuş, ama bunu duymamış gibi yapıyoruz ben baştan beri biliyormuşum gibi davranıyoruz djfjdsfdjsfdjs",
    "29/11/2020 - 19:37:30; Nesrinim: [ Quote from 29/11/2020 - 19:34:48: 3EB0A2C54E5424F02204 ]; Zjejjfjsjdjd",
    "29/11/2020 - 19:37:32; Nesrinim: Yalaan",
    "29/11/2020 - 19:37:40; Nesrinim: [ Quote from 29/11/2020 - 19:36:06: 3EB03304AF8A17141EE4 ]; Şunu duyunca bir planım olasi geldi",
    "29/11/2020 - 19:37:52; ME: Yaptım daha önce de oldu biraz",
    "29/11/2020 - 19:38:25; ME: [ Quote from 29/11/2020 - 19:37:40: 06A9E9966F4E262B98F58B6BAE3E1A37 ]; Aaa sen bilirsin valla kendin kaybedersin iddialıyım pizzada",
    "29/11/2020 - 19:38:41; ME: ",
    "29/11/2020 - 19:42:04; Nesrinim: Jsjxhshxhx",
    "29/11/2020 - 19:42:25; Nesrinim: Yaparim diyorsan inanicam artik napayım",
    "29/11/2020 - 19:42:39; Nesrinim: Ben mesela malzemeleri doğrayabilirim diyorum",
    "29/11/2020 - 19:42:48; Nesrinim: Sen de bana inan doğrarım ekjfjej",
    "29/11/2020 - 19:43:21; ME: Fkdkkd tamam hem bir ortak çalışma olur",
    "29/11/2020 - 19:43:45; Nesrinim: Guzel olursa",
    "29/11/2020 - 19:43:51; Nesrinim: Daha once pizza yaptın mi sorularina",
    "29/11/2020 - 19:43:55; Nesrinim: Evet demeyi planlıyorum",
    "29/11/2020 - 19:44:00; Nesrinim: Ben yapmisim gibi",
    "29/11/2020 - 19:44:01; Nesrinim: Jejchshch",
    "29/11/2020 - 19:44:03; ME: Jdjdjdjdkdkdkd",
    "29/11/2020 - 19:44:29; ME: Çirkin olursa da benle dalga geçiceksin baya kazan kazan durumu",
    "29/11/2020 - 19:45:25; Nesrinim: Oley evet kskdjsj",
    "29/11/2020 - 19:45:31; Nesrinim: 1 yil falan dalga gecerim",
    "29/11/2020 - 19:45:33; Nesrinim: Hazir misin",
    "29/11/2020 - 19:46:22; ME: Bir daha istiyiceksin bu sefer de ben naza çekicem şef tribine giricem",
    "29/11/2020 - 19:47:35; Nesrinim: Çok iddialısın skjdjsj",
    "29/11/2020 - 19:47:40; Nesrinim: Umarım öyle olur",
    "29/11/2020 - 19:47:50; Nesrinim: Sonuçta aç kalmak istemeyiz.."],
    ["30/11/2020 - 21:25:58; ME: Of bak ne dicem",
    "30/11/2020 - 21:26:07; Nesrinim: Annem diyor sen hicbir sey yapmadın di mi itiraf et",
    "30/11/2020 - 21:26:13; Nesrinim: Dedim yo her seyi ben yaptım",
    "30/11/2020 - 21:26:19; ME: Babamı aradım dedi ki, özel tava mı aldın fazla güzel görünüyor",
    "30/11/2020 - 21:26:27; Nesrinim: Ooooo",
    "30/11/2020 - 21:26:32; ME: [ Quote from 30/11/2020 - 21:26:13: 5E3F22D2E0A2926319857D07CE726A63 ]; ",
    "30/11/2020 - 21:26:33; Nesrinim: Babadan onay alındı",
    "30/11/2020 - 21:26:44; Nesrinim: Ben de çok beğendim",
    "30/11/2020 - 21:26:47; Nesrinim: Biliyorsun sen bu işi",
    "30/11/2020 - 21:26:50; ME: İnşallah yaptırır sana",
    "30/11/2020 - 21:26:51; ME: Sjjsjsjdjdk",
    "30/11/2020 - 21:26:56; Nesrinim: Kajdjejcjhdhc",
    "30/11/2020 - 21:27:00; Nesrinim: Yaparsam atarim foto",
    "30/11/2020 - 21:27:03; Nesrinim: Güleriz",
    "30/11/2020 - 21:27:10; ME: Djdjkd anlaştık",
    "30/11/2020 - 21:27:18; Nesrinim: Ssndjjsjc"],
    ["03/12/2020 - 10:45:35; ME: Aaaaaaaaaaaaaaa",
    "03/12/2020 - 10:45:41; ME: Okula çağrılmışız 🥺",
    "03/12/2020 - 10:46:42; ME: Aha bir de uyku modundasın uyanmazsan arayıp uyandırıcam jsjdkdkdkkskss",
    "03/12/2020 - 10:51:17; Nesrinim: Msjxjshc",
    "03/12/2020 - 10:51:26; Nesrinim: Günaydıınn",
    "03/12/2020 - 10:51:41; ME: Tüh günaydın 😄",
    "03/12/2020 - 10:52:02; Nesrinim: Ben uyuyor gibi yapayım",
    "03/12/2020 - 10:52:06; Nesrinim: Sen ara",
    "03/12/2020 - 10:52:08; Nesrinim: Ksjfhdhyc",
    "03/12/2020 - 10:52:10; ME: Fjfıdkdkkdkdkd",
    "03/12/2020 - 10:52:14; ME: Hizmette sınır tanımıyosun",
    "03/12/2020 - 10:52:27; Nesrinim: Ben 1 e nasıl yetişeyim",
    "03/12/2020 - 10:52:31; ME: Ben hazırlanmaya başladım",
    "03/12/2020 - 10:52:53; ME: Gerçi senin saçın kurumaz çok saö sorunları",
    "03/12/2020 - 10:53:01; ME: Allahtan kelim 🥺",
    "03/12/2020 - 10:53:08; Nesrinim: Jdjchdh",
    "03/12/2020 - 10:53:09; ME: ",
    "03/12/2020 - 10:53:18; Nesrinim: Iyisin iyi",
    "03/12/2020 - 10:53:29; ME: Napıcaksın gelicek misin",
    "03/12/2020 - 10:53:37; Nesrinim: Gelicem tabii",
    "03/12/2020 - 10:53:37; ME: Yoksa uyuyor gibi mi favranıcaksın",
    "03/12/2020 - 10:53:41; Nesrinim: [ Quote from 03/12/2020 - 10:53:37: 95C55D6437B29EB19FB156A11B02C6F9 ]; Keucudycyy",
    "03/12/2020 - 10:54:01; Nesrinim: Bu fikir yeni çıkmıştı aklımdan",
    "03/12/2020 - 10:54:03; Nesrinim: Naptıınn",
    "03/12/2020 - 10:54:06; ME: Çıkarken haberleşelim istersen beraber gideriz",
    "03/12/2020 - 10:54:42; ME: Ama kızıyorlar ben 2deki derse uyuyakalıp geç gitmiştim 8.sınıf dershanesinde hoca çok dalga geçmişti kdkdmf",
    "03/12/2020 - 10:54:45; ME: İnanmamıştı",
    "03/12/2020 - 10:54:52; ME: Oysaki ben uyumuştum kdkdkdkdkd",
    "03/12/2020 - 10:54:54; Nesrinim: Jdjcufhchudycyu",
    "03/12/2020 - 10:54:58; Nesrinim: Ya smjdhshxhhdhd",
    "03/12/2020 - 10:55:08; Nesrinim: Tamam çıkarken haberleselim",
    "03/12/2020 - 10:55:16; Nesrinim: Kacta çıkmam gerekecek benim",
    "03/12/2020 - 10:55:22; Nesrinim: Ona göre hazırlanayım",
    "03/12/2020 - 10:55:29; ME: 12 civarı çıkmak lazım",
    "03/12/2020 - 10:55:32; Nesrinim: Şu an yarı uyuyor gibiyim",
    "03/12/2020 - 10:55:39; Nesrinim: Beynim tam çalışmıyor galiba",
    "03/12/2020 - 10:55:50; ME: Kfdkdkdld güç tasarrufu modu",
    "03/12/2020 - 10:55:58; Nesrinim: 12 civarını",
    "03/12/2020 - 10:56:02; Nesrinim: 12 olarak alıyorum",
    "03/12/2020 - 10:56:09; ME: Tamam 12 mantıklı",
    "03/12/2020 - 10:56:30; ME: Koş hazırlan geç kalıcaz yoksa ben de hazırlanayım",
    "03/12/2020 - 10:56:35; Nesrinim: Anlaştık simdi gidip hazırlanayım",
    "03/12/2020 - 11:50:17; Nesrinim: Nerde bulusalim",
    "03/12/2020 - 11:50:31; ME: Sen hazır mısın",
    "03/12/2020 - 11:50:49; Nesrinim: Evet",
    "03/12/2020 - 11:51:02; ME: Ben sana doğru geldim",
    "03/12/2020 - 11:51:13; ME: Doğru geldiysem inince buluşuruz yoksa beni bulman lazım djfjjdkdkdkf",
    "03/12/2020 - 11:51:43; Nesrinim: Ana yol tarafinda misin",
    "03/12/2020 - 11:51:50; Nesrinim: Yoksa şok yolunda mi",
    "03/12/2020 - 11:51:58; ME: Tam ortada",
    "03/12/2020 - 11:52:04; Nesrinim: Ksjfusjfjjsjfjf",
    "03/12/2020 - 11:52:07; ME: Senin yol olduğunu tahmin ediyorum",
    "03/12/2020 - 11:52:09; ME: İndin mi",
    "03/12/2020 - 11:52:13; Nesrinim: Hayir",
    "03/12/2020 - 11:52:18; Nesrinim: Inince goreyim o zaman",
    "03/12/2020 - 11:52:23; Nesrinim: Dur iniyorum",
    "03/12/2020 - 11:52:24; Nesrinim: Msjchdhch",
    "03/12/2020 - 11:52:24; ME: Aynen"],
    ["03/12/2020 - 21:33:12; ME: Ben de çok uzlaşılmaz bir imaj çizdim, gayet uyumluyum ben kfkfkfkfkgkgkgk",
    "03/12/2020 - 21:34:41; Nesrinim: Lütfen",
    "03/12/2020 - 21:34:45; Nesrinim: Imaj cizme",
    "03/12/2020 - 21:34:51; Nesrinim: Kendin gibi ol",
    "03/12/2020 - 21:35:00; Nesrinim: -mevlana celalettin rumi",
    "03/12/2020 - 21:35:12; ME: Ben Nesrin diye biliyorum ama",
    "03/12/2020 - 21:36:16; ME: Tamam çok boş yaptım kabul ediyorum ben utandım gidiyorum kaçıyorum uzaklara",
    "03/12/2020 - 21:36:32; Nesrinim: Ayip ettin",
    "03/12/2020 - 21:36:39; Nesrinim: Neyyyssse",
    "03/12/2020 - 21:36:44; Nesrinim: Hadi kaç madem",
    "03/12/2020 - 21:36:46; Nesrinim: Napalım",
    "03/12/2020 - 21:36:52; ME: Bak yazarken toplayamıyorum da djdjs",
    "03/12/2020 - 21:36:58; Nesrinim: Lsicjdjjcjdjfjjd",
    "03/12/2020 - 21:37:07; Nesrinim: Neyse o kadar dondurma yedik",
    "03/12/2020 - 21:37:10; Nesrinim: Ben onu dusunup",
    "03/12/2020 - 21:37:13; Nesrinim: Bunu unuturum",
    "03/12/2020 - 21:37:16; ME: Yarın görüşürüz",
    "03/12/2020 - 21:37:20; ME: Ooo harika üşütme de",
    "03/12/2020 - 21:37:32; ME: Yarın da gidelim mi beraber kaçta çıkıcaksın",
    "03/12/2020 - 21:37:43; Nesrinim: 10 da mi ders",
    "03/12/2020 - 21:38:02; ME: Aynen",
    "03/12/2020 - 21:38:04; Nesrinim: 10 daymış",
    "03/12/2020 - 21:38:16; Nesrinim: 9 gibi çıkarım",
    "03/12/2020 - 21:38:25; Nesrinim: 9 u 10 geçe falan en geç",
    "03/12/2020 - 21:38:40; Nesrinim: Ama sen buralara gelme istersen durakta da buluşabiliriz",
    "03/12/2020 - 21:38:50; ME: Alttaki durak mı yine",
    "03/12/2020 - 21:39:03; Nesrinim: Bugun otobuse bindiğimiz yer",
    "03/12/2020 - 21:39:20; ME: Tamam ben kargo işine göre sana haber vereyim",
    "03/12/2020 - 21:39:40; ME: Yani daha doğrusu haberleşelim işte djdjdmd",
    "03/12/2020 - 21:39:54; ME: Bu akşam çok saçmalıyorum dur desene bana",
    "03/12/2020 - 21:40:01; Nesrinim: Lsjcjdjcjdjcj",
    "03/12/2020 - 21:40:09; Nesrinim: Bugun neden boyleyiz acaba",
    "03/12/2020 - 21:40:14; Nesrinim: Olsun napalim",
    "03/12/2020 - 21:40:19; Nesrinim: Tamam yarin haberleşiriz",
    "03/12/2020 - 21:40:25; Nesrinim: Sıkıntı cikmadan kacayim artık",
    "03/12/2020 - 21:40:27; ME: İyi gecelerrr",
    "03/12/2020 - 21:40:31; Nesrinim: Iyi aksamlar 😂",
    "03/12/2020 - 21:40:39; ME: Yine sıkıntı çıktı jdjfjjf",
    "03/12/2020 - 21:40:47; Nesrinim: Lsjcjdjcjjdjcu",
    "03/12/2020 - 21:40:52; Nesrinim: Görüşürüzz"],
    ["05/12/2020 - 17:04:01; Nesrinim: Ben de kendime aşırı kötü bir kahve yapmışım da",
    "05/12/2020 - 17:04:06; Nesrinim: Aklima geldin",
    "05/12/2020 - 17:04:08; Nesrinim: Lajcushchhehchhdhchhdhd",
    "05/12/2020 - 17:04:20; ME: ",
    "05/12/2020 - 17:04:51; ME: Saol valla bir dahakine sen yap da içelim",
    "05/12/2020 - 17:05:05; Nesrinim: Sitem mi var burda",
    "05/12/2020 - 17:05:12; Nesrinim: Parantez ici ünlemleri göremiyorummm",
    "05/12/2020 - 17:05:23; ME: Yoo temenni",
    "05/12/2020 - 17:05:37; Nesrinim: Yapayım sana bir kahve",
    "05/12/2020 - 17:05:48; Nesrinim: Yapabilirim yani",
    "05/12/2020 - 17:05:52; Nesrinim: O iş bende 😂"],
    ["08/12/2020 - 16:35:05; Nesrinim: Böyle oldu ayıp mi oldu sana 😶",
    "08/12/2020 - 16:35:11; Nesrinim: Ben geçicem stara",
    "08/12/2020 - 16:35:18; Nesrinim: Haberin olsunv",
    "08/12/2020 - 16:51:37; ME: Yok ya ne ayıbı",
    "08/12/2020 - 16:52:00; ME: Minik bir satış olabilir ama snsksjksls",
    "08/12/2020 - 16:52:17; ME: Naptın aldın mı çıktı",
    "08/12/2020 - 16:52:38; Nesrinim: Yok almadim",
    "08/12/2020 - 16:52:40; Nesrinim: Simdi indik",
    "08/12/2020 - 16:52:51; Nesrinim: [ Quote from 08/12/2020 - 16:52:00: 1027F4CF66533256A634DCF703B2685E ]; Off fark etmedim ben boyle olacağını",
    "08/12/2020 - 16:53:02; ME: Tüh bana da al dicektim",
    "08/12/2020 - 16:53:20; ME: Geliyorum ben de azıcık nef taraflı indim erken gidersen aklında olsun",
    "08/12/2020 - 16:53:49; Nesrinim: Tamam olsun"],
    ["11/12/2020 - 21:03:20; ME: Naptın görüşmeyeli",
    "11/12/2020 - 21:03:25; Nesrinim: Ya of ya",
    "11/12/2020 - 21:03:30; Nesrinim: Geçmişsin beni",
    "11/12/2020 - 21:03:32; ME: Nasıl",
    "11/12/2020 - 21:03:34; ME: Açıklandı mı",
    "11/12/2020 - 21:03:36; ME: Aaaaaa",
    "11/12/2020 - 21:03:37; ME: Aaaaaa",
    "11/12/2020 - 21:03:43; Nesrinim: [ Image: Media/WhatsApp Images/IMG-20201211-WA0017.jpg ]",
    "11/12/2020 - 21:04:13; ME: Hehehhehehehe",
    "11/12/2020 - 21:04:23; ME: Çok da yakın almışız",
    "11/12/2020 - 21:04:41; Nesrinim: Sayilmaaazzzz",
    "11/12/2020 - 21:04:43; ME: Eledim",
    "11/12/2020 - 21:04:47; Nesrinim: Yo",
    "11/12/2020 - 21:04:52; ME: Olmaz",
    "11/12/2020 - 21:04:54; ME: Anlaştık bir kere",
    "11/12/2020 - 21:04:55; Nesrinim: Ya nasil ya",
    "11/12/2020 - 21:04:59; ME: Pasta yicez",
    "11/12/2020 - 21:05:04; Nesrinim: Bshchdhch",
    "11/12/2020 - 21:05:08; Nesrinim: Aslında sanki",
    "11/12/2020 - 21:05:10; ME: Neli seversin",
    "11/12/2020 - 21:05:11; Nesrinim: Yazilida gecmisim",
    "11/12/2020 - 21:05:12; Nesrinim: Baya",
    "11/12/2020 - 21:05:19; Nesrinim: Hoca beni sevmedi",
    "11/12/2020 - 21:05:20; Nesrinim: ..",
    "11/12/2020 - 21:05:26; ME: Önemli olan sonuç",
    "11/12/2020 - 21:06:16; ME: Offf hala şaşkınım",
    "11/12/2020 - 21:06:22; ME: Geçtim be oley beee",
    "11/12/2020 - 21:06:29; Nesrinim: Dlcjjshchdhhc",
    "11/12/2020 - 21:06:31; ME: ",
    "11/12/2020 - 21:06:33; Nesrinim: Tebrik ederim",
    "11/12/2020 - 21:06:36; Nesrinim: Ama üzgünüm",
    "11/12/2020 - 21:06:41; Nesrinim: Nasıl gecersin",
    "11/12/2020 - 21:06:41; ME: Niye :(",
    "11/12/2020 - 21:06:43; Nesrinim: Nassiiil",
    "11/12/2020 - 21:06:46; ME: Hahahhaa",
    "11/12/2020 - 21:06:57; ME: Ben de seni tebrik ederim birinci olmasan da",
    "11/12/2020 - 21:07:33; Nesrinim: Birincilik de gitti",
    "11/12/2020 - 21:07:35; Nesrinim: 100 alan var",
    "11/12/2020 - 21:07:42; ME: Ona ben de çok şaşırdım",
    "11/12/2020 - 21:07:44; Nesrinim: Cok iyi her seyi biliyor",
    "11/12/2020 - 21:07:54; ME: 100 çok yahu",
    "11/12/2020 - 21:08:09; Nesrinim: Neyse ne zaman alcan",
    "11/12/2020 - 21:08:10; Nesrinim: Pastami",
    "11/12/2020 - 21:08:17; Nesrinim: Bari tadini cikarayim olayin",
    "11/12/2020 - 21:08:18; Nesrinim: ...",
    "11/12/2020 - 21:08:35; ME: Neli seviyorsun",
    "11/12/2020 - 21:08:41; ME: Valla sen ne zaman müsaitsen",
    "11/12/2020 - 21:08:54; Nesrinim: Çikolatali sevmiyorum",
    "11/12/2020 - 21:08:57; Nesrinim: O kadar",
    "11/12/2020 - 21:09:00; Nesrinim: Tek kriter bu",
    "11/12/2020 - 21:09:10; Nesrinim: Uzdu mu bu durum seni",
    "11/12/2020 - 21:09:10; ME: Hmmm",
    "11/12/2020 - 21:09:11; Nesrinim: Skjcjdjc",
    "11/12/2020 - 21:09:18; ME: Yoo abim de yemez çikolatalı",
    "11/12/2020 - 21:09:41; Nesrinim: Heh iyi bari",
    "11/12/2020 - 21:09:44; Nesrinim: Genelde üzüyor",
    "11/12/2020 - 21:09:50; Nesrinim: Herkes cikolatali seviyor",
    "11/12/2020 - 21:09:51; Nesrinim: Ne garip",
    "11/12/2020 - 21:10:14; ME: Ben yiyorum ama en sevdiğim meyveli herhalde",
    "11/12/2020 - 21:10:25; ME: Sen ne zaman müsait olursan alırım",
    "11/12/2020 - 21:10:41; Nesrinim: Yanni şimdi hicbiri benim yaptigim keklerin falan",
    "11/12/2020 - 21:10:44; Nesrinim: Yerini tutamaz ama",
    "11/12/2020 - 21:10:46; Nesrinim: Olsun",
    "11/12/2020 - 21:10:51; Nesrinim: Idare ederiz",
    "11/12/2020 - 21:10:52; Nesrinim: Sljfjejfjjdjfud",
    "11/12/2020 - 21:10:54; ME: Of",
    "11/12/2020 - 21:10:57; ME: Yapsana bana bir ara ya",
    "11/12/2020 - 21:11:06; ME: Valla bana tatlı yapsan ben çok sevinirim sjdkdkd",
    "11/12/2020 - 21:11:40; ME: Tabi pastadan sonra",
    "11/12/2020 - 21:11:46; Nesrinim: Yani pastadan mi",
    "11/12/2020 - 21:11:50; Nesrinim: Kaçıyorsun acaba",
    "11/12/2020 - 21:11:53; Nesrinim: Bilemedim şimdi",
    "11/12/2020 - 21:12:11; ME: [ Quote from 11/12/2020 - 21:11:40: B6D5C0B22F1658B2C9C23F1A37F9757B ]; ",
    "11/12/2020 - 21:12:18; ME: Oku oku",
    "11/12/2020 - 21:12:27; Nesrinim: Hehehe",
    "11/12/2020 - 21:12:30; Nesrinim: Yapayım",
    "11/12/2020 - 21:12:35; Nesrinim: Tatlı yapmak cok eglenceli",
    "11/12/2020 - 21:13:07; ME: Browni",
    "11/12/2020 - 21:13:09; ME: Dmştn",
    "11/12/2020 - 21:13:57; ME: Üf neyse çabuk müsait ol pasta yiyelim jdjdjd"],
    ["11/12/2020 - 21:24:35; ME: Ne zaman pasta yiyelim 🥺",
    "11/12/2020 - 21:24:51; ME: Haftasonu yasak olmasın aaaağğğğğ",
    "11/12/2020 - 21:25:13; ME: Seni haftasonu da rahatsız etmek istiyorum djdjfkfkkfkfkfkfk",
    "11/12/2020 - 21:25:21; Nesrinim: Ndjcudjcjjd",
    "11/12/2020 - 21:25:30; Nesrinim: Yasaklarin abv",
    "11/12/2020 - 21:25:42; Nesrinim: Yeriz en yakin zamanda",
    "11/12/2020 - 21:25:51; ME: Bakkala diye çıkıp kaçıyorum yasaklardan",
    "11/12/2020 - 21:26:06; ME: Alışveriş poşetimle djdjjd",
    "11/12/2020 - 21:26:10; Nesrinim: Bana mi gelcen bakkala diye çıkıp kdkcjjs",
    "11/12/2020 - 21:26:25; ME: Valla şarkısını atıcaktım ama söylemiş olduk djjdjd"],
    ["11/12/2020 - 21:38:32; ME: Ohoo",
    "11/12/2020 - 21:40:07; ME: Sınav sonrası dönem efekti bitmiş :(",
    "11/12/2020 - 21:40:51; Nesrinim: Daha ne kadar güleyim",
    "11/12/2020 - 21:40:56; Nesrinim: Yoruldum gülmekten",
    "11/12/2020 - 21:41:04; Nesrinim: O yuzden gülemiyorum galiba",
    "11/12/2020 - 21:41:14; Nesrinim: Kaslarıma bir seyler oldu",
    "11/12/2020 - 21:41:44; ME: İyi güldük ya haklısın yeter sonraya da kalsın",
    "11/12/2020 - 21:43:48; Nesrinim: [ilk kalpli köpek stikırı]"],
    ["12/12/2020 - 20:54:49; ME: Nasıl yapıcaz şimdi",
    "12/12/2020 - 20:55:02; Nesrinim: Yapamiycaz galiba",
    "12/12/2020 - 20:55:12; ME: İşin mi çıktı :(",
    "12/12/2020 - 20:55:16; Nesrinim: Nasil bulcaz sitede",
    "12/12/2020 - 20:55:18; Nesrinim: Birbirimizi",
    "12/12/2020 - 20:55:20; Nesrinim: [ Quote from 12/12/2020 - 20:55:12: DA5D3F2E5E07CEECD4AA34936C47C499 ]; Yo",
    "12/12/2020 - 20:55:34; ME: Odalı oluyor onlar hallederiz",
    "12/12/2020 - 20:55:56; ME: Bizim servisçi 3 kişi oda kurup milleti bile kekliyordu odkdkdkd",
    "12/12/2020 - 20:56:11; Nesrinim: Ksjdueuuchdhcuhd",
    "12/12/2020 - 20:56:17; Nesrinim: Serviscinin numarasi var mi",
    "12/12/2020 - 20:56:20; Nesrinim: O abi anliyor gibi",
    "12/12/2020 - 20:56:21; ME: Ksksksksksks",
    "12/12/2020 - 20:56:22; Nesrinim: Bu islerden",
    "12/12/2020 - 20:56:26; Nesrinim: Bize bir el atsin",
    "12/12/2020 - 20:56:27; ME: Dur halledicez şimdi",
    "12/12/2020 - 20:56:28; Nesrinim: Sjfhhehch",
    "12/12/2020 - 20:56:29; ME: 101 mi",
    "12/12/2020 - 20:56:33; Nesrinim: Eveett",
    "12/12/2020 - 20:56:41; Nesrinim: En az hangisini biliyorsan",
    "12/12/2020 - 20:56:42; Nesrinim: O",
    "12/12/2020 - 20:56:44; ME: Sjsjjsjssj",
    "12/12/2020 - 20:57:12; ME: Ariyim mi müsaitsen diğer türlü çok birlikte olmuyor dkdkdkdk",
    "12/12/2020 - 20:57:40; Nesrinim: Anlamadim",
    "12/12/2020 - 20:57:42; Nesrinim: Ama olur",
    "12/12/2020 - 20:57:43; Nesrinim: Ksjchehch"],
    ["13/12/2020 - 19:55:50; ME: [ Video: Media/WhatsApp Video/VID-20201213-WA0019.mp4 ]",
    "13/12/2020 - 19:55:56; ME: Oa",
    "13/12/2020 - 19:56:02; ME: Jdjsıskdsk",
    "13/12/2020 - 19:56:22; ME: Arkadaşlarım kedileei buluşturmuç",
    "13/12/2020 - 19:56:24; Nesrinim: Bu kediler niye manyak",
    "13/12/2020 - 19:56:28; ME: [ Image (gif/mp4): Media/WhatsApp Animated Gifs/VID-20201213-WA0024.mp4 ]",
    "13/12/2020 - 19:56:38; ME: Harika düşüyor jekskdksjdj",
    "13/12/2020 - 19:56:57; Nesrinim: Dusucek gibi bak",
    "13/12/2020 - 19:56:59; Nesrinim: Skjcjdhchdhch",
    "13/12/2020 - 19:57:05; ME: [ Quote from 13/12/2020 - 19:56:24: 36634588784FFE529B10F92F1338AA88 ]; Bilmiyorum ki çok iyi ama",
    "13/12/2020 - 19:57:25; ME: Oh no videoları var ya onlar gibi olmuş",
    "13/12/2020 - 19:57:43; ME: O değil de siz de mi bana yazıcaktınız çok hızlı okundu ._.",
    "13/12/2020 - 19:57:47; ME: ",
    "13/12/2020 - 19:57:57; Nesrinim: Ksjcjdhc",
    "13/12/2020 - 19:58:06; Nesrinim: Evet diyeyim",
    "13/12/2020 - 19:58:08; Nesrinim: Ksjfhdhfhd",
    "13/12/2020 - 19:58:12; ME: Haydeee",
    "13/12/2020 - 19:58:16; ME: Yakalandın",
    "13/12/2020 - 19:58:24; Nesrinim: Ne yazacagimi düşünüyordum",
    "13/12/2020 - 19:58:28; Nesrinim: Ekranı acip",
    "13/12/2020 - 19:58:30; Nesrinim: Kehcuduchudhc"],
    ["15/12/2020 - 01:05:58; Nesrinim: [ Quote from 15/12/2020 - 01:03:26: 039BA65C1CE0A4D2A6CE3D038E77C8C3 ]; Bu nasil bu kadar",
    "15/12/2020 - 01:06:02; Nesrinim: Olayla baglantili oldu",
    "15/12/2020 - 01:06:40; ME: Valla bilemiyorum djsjjdjs",
    "15/12/2020 - 01:06:49; ME: Süper bir insanım galiba",
    "15/12/2020 - 01:06:51; ME: Büyü",
    "15/12/2020 - 01:07:04; Nesrinim: [ Quote from 15/12/2020 - 01:06:49: 05D9F4BE007F964AF4D7529006DB53E4 ]; ",
    "15/12/2020 - 01:07:14; Nesrinim: Öyle mi diyelim",
    "15/12/2020 - 01:07:28; ME: Onu ben yaptım nasıl bir ileri görüşlülük",
    "15/12/2020 - 01:07:31; ME: Hesaplasana",
    "15/12/2020 - 01:07:32; ME: Nsjsksjs",
    "15/12/2020 - 01:07:38; Nesrinim: Oa",
    "15/12/2020 - 01:08:08; Nesrinim: Ben bunu kullanirim illa bir yerde mi dedin",
    "15/12/2020 - 01:08:10; Nesrinim: Sad oldu su an",
    "15/12/2020 - 01:09:00; ME: Ben kullanıcı adı olarak acedia, sloth ve mertmit kullanıyorum",
    "15/12/2020 - 01:09:29; Nesrinim: 🤔",
    "15/12/2020 - 01:09:42; ME: [ Quote from 15/12/2020 - 01:08:10: 0974ABFE101E600AD21400C0FAF628FD ]; Bütün köpek olanları sana atarım diye yaptım be",
    "15/12/2020 - 01:09:56; Nesrinim: Yaaaa😍"],
    ["15/12/2020 - 01:16:05; Nesrinim: Yildizlar nasil",
    "15/12/2020 - 01:16:12; Nesrinim: Bugün birkaç kere aklima geldiler",
    "15/12/2020 - 01:16:17; Nesrinim: Enteresan sekilde",
    "15/12/2020 - 01:16:23; Nesrinim: Nasillar acaba diye sljcueuc",
    "15/12/2020 - 01:16:23; ME: Hmm",
    "15/12/2020 - 01:16:26; ME: Ben mi geldim yani",
    "15/12/2020 - 01:16:32; ME: ",
    "15/12/2020 - 01:16:34; Nesrinim: Yaannnii",
    "15/12/2020 - 01:16:47; ME: Şimdi odamda değilim bakiyim hemen",
    "15/12/2020 - 01:17:06; Nesrinim: Hosuna gidiyor mu uyurken",
    "15/12/2020 - 01:17:16; ME: Uyurken nasıl gitsin ki sksksksks",
    "15/12/2020 - 01:17:27; Nesrinim: Geycydyycydc"],
    ["15/12/2020 - 20:46:24; Nesrinim: Miden nasil oldu",
    "15/12/2020 - 20:46:27; Nesrinim: Iyi misin bari",
    "15/12/2020 - 20:46:39; ME: Şakası yokmuş bozmuş beni",
    "15/12/2020 - 20:46:46; ME: Yağ bana dokunuyor karar verdim",
    "15/12/2020 - 20:46:57; ME: Bu gün çok dispepsi dinledim",
    "15/12/2020 - 20:47:22; ME: Ama iyiyim",
    "15/12/2020 - 20:47:39; ME: Tabi sizleyken daha iyiydim hehe",
    "15/12/2020 - 20:48:31; ME: Niye resmi konuşuyorum bilmiyorum aaaaağağ",
    "15/12/2020 - 20:49:24; Nesrinim: Jsjchshchduc",
    "15/12/2020 - 20:49:40; ME: ",
    "15/12/2020 - 20:49:41; Nesrinim: Nedeeenn",
    "15/12/2020 - 20:50:17; ME: Sevimli köpeklerin arkasına saklandım fdskfdks",
    "15/12/2020 - 20:50:31; Nesrinim: Hehehe",
    "15/12/2020 - 20:50:32; Nesrinim: Yemedim",
    "15/12/2020 - 20:50:36; Nesrinim: Üzgünüm lsifueuc",
    "15/12/2020 - 20:50:50; ME: Ben evde iyi değilmişim",
    "15/12/2020 - 20:51:02; ME: Senle iyiymişim şu an onu değerlendirdim"],
    ["17/12/2020 - 22:38:25; Nesrinim: Uyudun mu",
    "17/12/2020 - 22:38:32; Nesrinim: Uyuduysan dalga geceyim azicik",
    "17/12/2020 - 22:38:58; Nesrinim: Oa tek tik bu sende ne demek bilmiyorim ki",
    "17/12/2020 - 22:39:00; Nesrinim: Kekcjehch",
    "17/12/2020 - 23:19:21; ME: Ohooo",
    "17/12/2020 - 23:19:27; ME: Yakalandık",
    "17/12/2020 - 23:19:36; ME: Kısacık şey yaptım",
    "17/12/2020 - 23:19:41; ME: ",
    "17/12/2020 - 23:20:12; ME: Keyfin nasıl oldu",
    "17/12/2020 - 23:20:37; ME: Halledebildin mi ödevleri",
    "17/12/2020 - 23:22:18; Nesrinim: Msjcjehdh",
    "17/12/2020 - 23:22:22; Nesrinim: Nasil da yakaladim",
    "17/12/2020 - 23:22:31; ME: Yakalandım ensemden",
    "17/12/2020 - 23:22:33; ME: Pıt diye",
    "17/12/2020 - 23:23:13; ME: Senin mesaj sesini mehter marşı mı yapsam",
    "17/12/2020 - 23:23:29; ME: Aklıma en sesli niye bu geldi",
    "17/12/2020 - 23:23:40; ME: Çok utanç verici oldu bu cümle aaaaaa",
    "17/12/2020 - 23:24:50; Nesrinim: Sljcsjhchc",
    "17/12/2020 - 23:24:53; Nesrinim: Mehter mi",
    "17/12/2020 - 23:24:54; Nesrinim: Oa",
    "17/12/2020 - 23:24:56; Nesrinim: Nolur yapma",
    "17/12/2020 - 23:25:15; ME: Yapmiyim madem",
    "17/12/2020 - 23:25:34; ME: Zaten ben iki ileri bir geri hiç uğraşmıyorum",
    "17/12/2020 - 23:25:46; ME: Koşuyorum sana sjjsjsjsjsjsjjsns",
    "17/12/2020 - 23:25:48; ME: ",
    "17/12/2020 - 23:27:29; Nesrinim: Oa ksjcjshch",
    "17/12/2020 - 23:27:35; ME: Hişş",
    "17/12/2020 - 23:28:06; ME: Jsjsjds kaçtı"],
    ["18/12/2020 - 00:33:17; Nesrinim: Bir sey soylicem",
    "18/12/2020 - 00:33:26; Nesrinim: Ben neye ikna edilmeye calisiliyorum",
    "18/12/2020 - 00:33:29; Nesrinim: Ben bilmiyorum ki",
    "18/12/2020 - 00:33:44; ME: Okul sonrası buluşmaya",
    "18/12/2020 - 00:34:02; ME: Yemeği birlikte yeriz işte",
    "18/12/2020 - 00:34:27; Nesrinim: Ben bugun gibi çıkarsam",
    "18/12/2020 - 00:34:31; Nesrinim: Sana yazık olur",
    "18/12/2020 - 00:34:35; Nesrinim: Yapma bence...",
    "18/12/2020 - 00:34:36; Nesrinim: Dkjfhdhch",
    "18/12/2020 - 00:34:40; ME: Yani sen dersen ki beni kalmaya ikna et",
    "18/12/2020 - 00:34:46; ME: Ben yine imkanlarımı zorliyim",
    "18/12/2020 - 00:34:50; Nesrinim: Oa demem demem",
    "18/12/2020 - 00:34:53; ME: Ama o sana bağlı djdjsjd",
    "18/12/2020 - 00:35:01; Nesrinim: O fikir elendi de",
    "18/12/2020 - 00:35:05; ME: [ Quote from 18/12/2020 - 00:34:31: C515346174B68951D500D46FA09BCAFC ]; Olmaz olmaz",
    "18/12/2020 - 00:35:16; ME: Evine bırakırım seni aldığım gibi djdmkd",
    "18/12/2020 - 00:35:20; Nesrinim: Nrjfjrjcjjjrjfj",
    "18/12/2020 - 00:35:36; Nesrinim: Yarın konusuruz bunlari",
    "18/12/2020 - 00:35:38; Nesrinim: Amaaan"],
    ["31/12/2020 - 23:54:52; ME: Saat sana göre kaç ya",
    "31/12/2020 - 23:55:07; ME: Aynı anda girebiliyor muyuz yılbaşına kdkdksjs",
    "31/12/2020 - 23:58:29; Nesrinim: Lwicuejjchd",
    "31/12/2020 - 23:58:32; Nesrinim: 23.58",
    "31/12/2020 - 23:58:38; ME: Eyvah",
    "31/12/2020 - 23:58:41; ME: Benim 57",
    "31/12/2020 - 23:58:48; ME: Yeni 58 oldu djdns",
    "31/12/2020 - 23:59:18; Nesrinim: Giremeyecek miyiz yani",
    "31/12/2020 - 23:59:20; Nesrinim: Elendik mi",
    "01/01/2021 - 00:00:19; Nesrinim: Biz saydiiiik",
    "01/01/2021 - 00:00:22; Nesrinim: Mutlu yillarr",
    "01/01/2021 - 00:00:25; ME: Seneye görüşürüz",
    "01/01/2021 - 00:00:27; ME: Diyemedim",
    "01/01/2021 - 00:00:33; ME: Sana da mutlu yıllarrrr",
    "01/01/2021 - 00:01:11; ME: [ Image: Media/WhatsApp Images/Sent/IMG-20210101-WA0000.jpg; Telefonun da kafası karışık ]",
    "01/01/2021 - 00:07:32; Nesrinim: Lsicidicjjd",
    "01/01/2021 - 00:07:44; Nesrinim: Oldu oldu",
    "01/01/2021 - 00:08:09; Nesrinim: [ Quote from 01/01/2021 - 00:00:25: E44013425040C509993EB055CE1803DA ]; Dedin sayıyorum",
    "01/01/2021 - 00:09:27; ME: Oley",
    "01/01/2021 - 01:51:26; Nesrinim: Sarhos oldun mu",
    "01/01/2021 - 02:10:17; ME: Mmm",
    "01/01/2021 - 02:10:20; ME: Olmamı ister misin",
    "01/01/2021 - 02:14:08; Nesrinim: Yani evet",
    "01/01/2021 - 02:14:22; Nesrinim: Eğlenirim",
    "01/01/2021 - 02:14:43; ME: Yani biraz hoş kafam tabi",
    "01/01/2021 - 02:14:52; ME: Ama 2 shot daha atiym seni mi kırıcam",
    "01/01/2021 - 02:17:46; Nesrinim: Keucusuc",
    "01/01/2021 - 02:56:47; ME: Beni sarhoş mu etmeye çalışıyorsun",
    "01/01/2021 - 02:56:54; ME: ",
    "01/01/2021 - 02:58:12; Nesrinim: Uu",
    "01/01/2021 - 02:58:17; Nesrinim: Yoksa senden faydalanacak miyim",
    "01/01/2021 - 02:58:20; Nesrinim: Sarhos birinden",
    "01/01/2021 - 02:58:22; Nesrinim: ..."],
    ["14/03/2021 - 15:46:58; Nesrinim: Seni seviyorum 🖤"]
]