import anime from "animejs";
import _ from "lodash";

let txt = require('./messages.json')

for(let [i, ts] of txt.entries()) {
    for(let t of ts) {
        let dt = t.substring(0, 10);
        let tm = t.substring(13, 19);
        let who = t.substring(23, 24);
        who = (who == 'M') ? 'Mertin' : 'Nesrinim 🖤';
        const ros = t.substring(23);
        let msg = ros.substring(ros.indexOf(':')+1);
        let bd = document.querySelector('.speech-wrapper.n' + (i+1));
        bd.innerHTML += `<div class="bubble ${(who.startsWith('N') ? 'rside':'lside')} ${(who.startsWith('N') ? 'alt':'')}">
        <div class="txt">
        <p class="name ${(who.startsWith('N') ? 'alt':'')}">${who} <span>${dt}</span></p>
        <p class="message">${msg}</p>
        <span class="timestamp">${tm.substring(0, 5)}</span>
        </div>
        <div class="bubble-arrow ${(who.startsWith('N') ? 'alt':'')}"></div>
    </div>`;
    }
}
// Wrap every letter in a span
var textWrapper = document.querySelector('.t1');
textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

const tl = anime.timeline()
.add({
    targets: '.t1 .letter',
    translateY: [-100,0],
    easing: "easeOutExpo",
    duration: 1400,
    delay: (el, i) => 30 * i
});